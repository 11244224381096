import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Tech = ({tech}) => (
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-1">{tech}</span>
)

const Project = ({ project }) => {
    
    const techlist = project.frontmatter.technology.split(",").map(technology => <Tech tech={technology} />)
    return (
        <div class="flex p-1 w-full md:w-1/2 my-2">
            <div class="border border-gray-400 bg-white rounded-b p-4 flex flex-col justify-between leading-normal">
                <div class="mb-8">
                    <div className="text-sm">
                        <span className="text-gray-600">{project.frontmatter.rating}</span>
                        <span className="text-gray-600 float-right">{project.frontmatter.date}</span>
                        <p class="text-gray-600 ">{project.frontmatter.event}</p>
                    </div>
                <div class="text-gray-900 font-bold text-xl mb-2">{project.frontmatter.title}</div>
                    <p class="text-gray-700 text-base">
                        <div dangerouslySetInnerHTML={{ __html: project.html }} />
                    </p>
                </div>
                <div class="flex flex-col">
                    <div class="px-1 py-1">
                            {techlist}
                    </div>
                    <div class="text-sm">
                        <p class="text-gray-600">{project.frontmatter.description}</p>
                    </div>
                </div>
            </div>
        </div>
        )
};

export default () => {
    const data = useStaticQuery(pageQuery);

    const Projects = data.allMarkdownRemark.edges
        .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
        .map(edge => <Project key={edge.node.id} project={edge.node}></Project>)
    return (
        <div className="flex flex-wrap">
            {Projects}
        </div>
    )
};


const pageQuery = graphql`
  query ProjectQuery{
    allMarkdownRemark(
        filter: { fileAbsolutePath: {regex : "\/projects/"} },
        sort: { order: DESC, fields: [frontmatter___date] }
        ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          html
          frontmatter {
            date(formatString: "MMMM, YYYY")
            path
            title
            rating
            technology
            description
            event
          }
        }
      }
    }
  }
`