import React from 'react';

function Tech() {
    const words = {
        "Frontend": ["JavaScript", "React", "Sass", "TypeScript", "Gatsby"],
        "Backend": ["Python", "PostgreSQL", "MongoDB", "MQTT", "Redis", "OpenAPI", "REST", "GraphQL"],
        "DevOps": ["AWS", "Serverless", "Terraform", "Ansible", "Git", "Docker", "Linux", "Windows"],
        "Engineering": ["MATLAB", "C++", "LaTeX"]
    }

    const list = Object.keys(words).map(key => {
        const list = words[key].map(word => <span class="inline-block bg-gray-200 border-b border-gray-400 shadow rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-1">{word}</span>)
        return (
        <div className="text-base p-1 my-1">
            <span className="text-base">{key}</span>
            <ul className="text-xs">
                {list}
            </ul>
        </div>
        )
    }, this)
    
    return (
        <div className="p-2">
            The following list gives an overview of some technologies I have worked with and am familiar with:
            {list}
        </div>
    );
}

export default Tech;
