import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Tech from "../components/tech/tech"
import Projects from "../components/projects/projects"
import ProfessionalExperience from "../components/experience/professional.jsx"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl">

      <span className="text-xl" id="home">Welcome</span>

      <div id="about" className="p-1">
        <div className="text-2xl">Hi</div>
        <Image props="float-right ml-4 my-2 h-48 w-48" />
        <div className="p-1 text-lg">
          I am Nils and a Software Engineer from Dortmund, Germany.
          <div className="my-4">
            <p className="my-2">
              I have worked on various projects ranging from developing C++-based simulation software
              to new and innovative cloud-based IoT services using bleeding-edge technologies.
            </p>
            <p className="my-2">
            In my spare time, I'm a big fan of Hackathons - an overview of my projects can be found below.
            I am also curious to try out new technologies and like to work in an international environment.
            </p>
                  {
                    /*I worked as a Software Engineer with different technologies. At the Chair of High Frequency I was part of a team where we developed a simulation software for optical communication networks based on C++, Qt & Cuda.
                    At the German Aerospace Center (DLR e.V.) in Brunswick I focused on data analysis with Python for Unmanned Air Vehicles.
                    After writing my Bachelor's Thesis in coorperation with Bosch Rexroth I worked as a fulltime Software Engineer in the area of cloud computing. I was part
                    Bosch Rexroth in Dortmund and Fountain Inn, SC, USA.
                    Currently I am working for VMRay - a IT-Security company based in Bochum. During a three month stay in Boston (MA, US) I gained valuable experience in area of customer integration and support of marketing and sales.
                    */
                  }
                </div>
        </div>
      </div>

      <div id="professional">
        <div className="text-2xl">Professional Experience</div>
        <ProfessionalExperience />
      </div>

      <div id="tech" className="mt-4" >
        <div className="text-2xl">Tech Stack</div>
        <Tech />
      </div>

      <div className="mt-4" id="projects">
        <div className="text-2xl">Projects</div>
        <span className="text-xl p-1">Hackathon Projects</span>
        <Projects />
      </div>

    </div>
  </Layout>
)

export default IndexPage
