import React from "react"

const ProfessionalExperienceRow = ({ data }) => {
    const locations = data.locations.map(location => <div>{location}</div>);

    return (
        <tr>
            <td className="px-3 py-4 border-b border-gray-200">
                <div className="">
                    <div className="text-sm leading-5 font-medium text-gray-900">{data.company}</div>
                    <div className="text-sm leading-5 text-gray-500">{data.startEnd}</div>
                </div>
            </td>
            <td className="px-3 py-4 border-b border-gray-200">
                <div className="text-sm leading-5 text-gray-900">{data.position.title}</div>
                <div className="text-sm leading-5 text-gray-500">{data.position.subtitle}</div>
            </td>
            <td className="px-3 py-4 border-b border-gray-200">
                <div className="text-sm leading-5 text-gray-900">
                    {locations}
                </div>
            </td>
        </tr>
    )
};

const ProfessionalExperience = () => {
    const jobs = [
        {
            company: "Wiz",
            position: { title: "Senior Solutions Engineer", subtitle: "Fulltime" },
            locations: ["💻 Remote"],
            duration: "Active",
            startEnd: "Aug 2024 - Today"
        },
        {
            company: "Palo Alto Networks",
            position: { title: "Solutions Architect - Prisma Cloud", subtitle: "Fulltime" },
            locations: ["💻 Remote"],
            duration: "~2.5 Years",
            startEnd: "Mar 2022 - Jul 2024"
        },
        {
            company: "Robert Bosch GmbH",
            position: { title: "Tech Lead | DevOps Software Engineer", subtitle: "Fulltime - Since Aug. 21 Tech Lead" },
            locations: ["🇩🇪 Stuttgart, Germany"],
            duration: "~1.5 Years",
            startEnd: "Oct 2020 - Feb 2022"
        },
        {
            company: "VMRay GmbH / Inc.",
            position: { title: "Software Engineer", subtitle: "Part-time / Intern" },
            locations: ["🇩🇪 Bochum, Germany", "🇺🇸 Boston (MA), USA"],
            duration: "~2 Years",
            startEnd: "Sep 2018 - Jul 2020"
        },
        {
            company: "Bosch Rexroth AG",
            position: { title: "Software Engineer", subtitle: "Intern, Part-time, Trainee" },
            locations: ["🇩🇪 Dortmund, Germany", "🇺🇸 Fountain Inn (SC), USA"],
            duration: "~2 Years",
            startEnd: "Jul 2016 - Jul 2018"
        },
        {
            company: "German Aerospace Center (DLR e.V.)",
            position: { title: "Software Engineer", subtitle: "Intern" },
            locations: ["🇩🇪 Brunswick, Germany"],
            duration: "3 Months",
            startEnd: "Apr 2016 - Jul 2016"
        },
        {
            company: "TU Dortmund - High Frequency Institute",
            position: { title: "Software Engineer", subtitle: "Part-time" },
            locations: ["🇩🇪 Dortmund, Germany"],
            duration: "~2 Years",
            startEnd: "May 2014 - Mar 2016"
        }
    ]

    const listItems = jobs.map(job => <ProfessionalExperienceRow data={job} />);

    return (
        <div className="flex flex-col w-full p-2">
            <div className="my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table>
                        <thead>
                            <tr>
                                <th className="px-3 py-3 border-b border-gray-400 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                                    Company
                  </th>
                                <th className="px-3 py-3 border-b border-gray-400 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                                    Position
                  </th>
                                <th className="px-3 py-3 border-b border-gray-400 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                                    Location
                  </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {listItems}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ProfessionalExperience;
